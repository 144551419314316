@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Laila:wght@300;400;500;600;700&display=swap");

.table-attachment tbody tr:nth-child(even) {
  border: none;
}

.table-attachment tbody tr:nth-child(odd) td {
  
}
.table-attachment tbody tr td {
}
.table-attachment tbody tr:nth-child(1) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}

.table-attachment tbody tr:nth-child(2) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}

.table-attachment tbody tr:nth-child(3) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}
.table-attachment tbody tr:nth-child(4) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}
.table-attachment tbody tr:nth-child(5) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}
.table-attachment tbody tr:nth-child(6) td:nth-child(1) {
  text-align: right;
  padding-right: 10px;
}

.table-attachment tbody tr td:nth-child(1) {
  background: rgb(68, 68, 68);
  color: white;
}
.table-attachment tbody tr:nth-child(1) {
  background: rgb(68, 68, 68);
  color: white;
  text-align: right;
}
.table-attachment tbody tr:nth-child(2) {
  background: rgb(226, 226, 226);
  border: none;
}
.table-attachment tbody tr:nth-child(3) {
  background: rgb(226, 226, 226);
  border: none;
}
.table-attachment tbody tr:nth-child(4) {
  background: rgb(226, 226, 226);
  border: none;
}
.table-attachment tbody tr:nth-child(5) {
  background: rgb(226, 226, 226);
  border: none;
}

.table-attachment thead tr th:first-child {
  text-align: right;
  padding-right: 1em;
}

.table-attachment tbody tr:last-child {
  border-bottom: 1px solid black;
}

.loader {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  position: fixed;
  background: rgba(221, 221, 221, 0.616);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader .file-upload {
  padding: 1em;
  font-size: 1.3em;
  background: rgb(187, 187, 187);
}

.dot {
  animation: blink 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}
.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0%, 20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

button:disabled{
  cursor: not-allowed;
  background: grey;
}